<template>
    <div>
        <div class="container">
            <h1 class="page-title">Frequently Asked Questions</h1>
        </div>

        <section class="section">
            <div class="container">
                <div class="questions-list">
                    <Question
                        v-for="q in $t('questions')"
                        :key="q.q"
                        :question="q.q"
                        :answer="q.a"
                    />
                </div>
            </div>
        </section>

        <FaqStructuredData :questions="$t('questions')" />
    </div>
</template>

<script>
import Question from "@/components/Question"
import FaqStructuredData from "@/components/FaqStructuredData"
export default {
    beforeCreate() {
        // Redirect to Zendesk help center
        document.location.href = this.$i18n.t("link.faq")
    },
    components: { Question, FaqStructuredData },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
            description: this.$i18n.t("meta.description"),
        })
    },

    i18n: {
        messages: {
            en: {
                meta: {
                    title: "FAQ",
                    description: "Frequently Asked Questions for Soracom Mobile eSIM",
                },
                questions: [
                    {
                        q: "What devices are compatible with Soracom Mobile?",
                        a: `Soracom Mobile is compatible with unlocked, eSIM-supported Dual SIM iOS/iPadOS devices. Please see our <a href="/compatibility/">Compatibility page</a> for the full list.`,
                    },
                    {
                        q: "How can I create an account?",
                        a: `Your account will be created automatically when you purchase your first eSIM through our app.`,
                    },
                    {
                        q: "Can I make phone calls or send SMS?",
                        a: `Soracom Mobile provides data service only. It does not provide a mobile phone number for voice calling or SMS. However, you can use apps such as WhatsApp, Skype or other VoIP apps for calls and messaging while you travel.`,
                    },
                    {
                        q: "Can I buy additional data if needed?",
                        a: `Yes, you can as long as your eSIM has not expired. When you have an active eSIM, you can buy additional data and load it on your eSIM by tapping “Data Top Up” on eSIM Details screen on the app.`,
                    },
                    {
                        q: "Can I re-use my eSIM that has expired?",
                        a: `If your eSIM has expired, it cannot be re-used. Please buy a new eSIM from Store.`,
                    },
                    {
                        q: "When does the eSIM plan period (30 days) start?",
                        a: `The eSIM is ready to use right after the purchase is complete. The 30-day period starts when you first use cellular data with it in a designated country.`,
                    },
                    {
                        q: "Can I move my eSIM to a new device?",
                        a: `Unfortunately, it is not possible with the current eSIM system architecture to transfer the eSIM to a different device.`,
                    },
                    {
                        q: "How long can I keep my eSIM unused?",
                        a: `Soracom Mobile eSIM is valid for 180 days from the purchase date.`,
                    },
                    {
                        q: "Can I use Personal Hotspot/data tethering with Soracom Mobile?",
                        a: `Yes, you can. To activate Personal Hotspot with Soracom Mobile on your device, tap Update on Carrier Settings Update dialog during the eSIM installation or go to Settings → General → Information to update the carrier settings.`,
                    },
                    {
                        q: "Can Soracom Mobile receive emergency alerts (ETWS) ?",
                        a: `We do not support emergency alerts. Please contact your carrier for behavior when inserting another company's SIM.`,
                    },
                ],
            },
            ja: {
                meta: {
                    title: "よくある質問",
                    description:
                        "ソラコム モバイルのeSIMやデータプラン、海外でのモバイルデータ通信に関するよくある質問",
                },
                questions: [
                    {
                        q: "どのような端末で使用できますか？",
                        a: `Soracom MobileはSIMロックフリー（SIMフリー）のeSIM対応iOS/iPadOS端末でご利用いただけます。詳しくは<a href="/ja/compatibility/">動作保証端末リスト</a>をご確認ください。`,
                    },
                    {
                        q: "アカウントはどのように作成できますか？",
                        a: `アプリから最初のeSIMを購入時、Apple IDを利用してサインインいただくだけで自動的にアカウントが作成されます。パスワードなどを設定する必要はありません。`,
                    },
                    {
                        q: "電話番号を使った発信やSMSは使用できますか？",
                        a: `Soracom Mobileはデータ専用サービスのため、通常の電話やSMSは利用できません。ただし、LINEやSkypeなどVoIPアプリを利用した電話機能は利用可能です。`,
                    },
                    {
                        q: "追加のデータを購入することはできますか？",
                        a: `購入されたeSIMの有効期限が残っている場合は、アプリのMy eSIMからデータを追加購入していただけます。有効期限が切れたeSIMについてはデータを追加することができないため、新たにeSIMをご購入ください。`,
                    },
                    {
                        q: "一度有効期限が切れたeSIMを再度次回利用することはできますか？",
                        a: `一度有効期限が切れたeSIMは再利用できないため、新たにeSIMをご購入いただく必要があります。基本的にはその都度購入するプリペイドeSIMとご理解ください。`,
                    },
                    {
                        q: "購入したeSIMの有効期限（30日）はいつから開始されますか？",
                        a: `各eSIMの有効期限は現地で最初にモバイルデータ通信を使用した時点から30日です。ただし、使用期限は購入日から180日後であるため、この期限が先に来る場合はその時点でeSIMは失効します。`,
                    },
                    {
                        q: "インストールしたeSIMを新しい端末に移すことはできますか？",
                        a: `現状は仕様の関係上eSIMを別の端末に移すことはできません。新しいeSIMをご購入いただきますようお願い致します。`,
                    },
                    {
                        q: "未使用のeSIMに使用期限はありますか？",
                        a: `すべてのeSIMは購入日より180日間有効です。`,
                    },
                    {
                        q: "Soracom Mobileでインターネット共有機能は使えますか？",
                        a: `はい、ご利用いただけます。インターネット共有機能を有効にするために、eSIMインストール時に表示される「キャリア設定アップデート」ダイアログでアップデートを選択いただくか、あるいは 設定 → 一般 → 情報 からアップデートを行ってください。`,
                    },
                    {
                        q:
                            "Soracom Mobileでは、緊急地震速報・エリアメール等(ETWS)を受信できますか?",
                        a: `緊急速報・エリアメール等には非対応です。他社SIM挿入時の挙動はお使いの通信事業者にお問い合わせ下さい。`,
                    },
                ],
            },
        },
    },
}
</script>

<style scoped>
.caret {
    margin-right: 8px;
}
.title-wrapper {
    padding: 0 15px;
}
.body h2 {
    padding-top: 120px;
}
.body h2:first-child {
    padding-top: 0;
}
.body h3 {
    padding-top: 80px;
}
.body h3:first-child {
    padding-top: 0;
}
</style>
